.contentblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .paginationContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ant-modal-title {
    text-align: center;
    font-size: 22px !important;
}

.ant-modal-body {
    .modal-div{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .ant-row {
            width: 100%;
            margin: 10px;
            .ant-col:nth-child(1) {
                font-weight: bold;
                color: rgba(37, 49, 73, 0.65);
            }
            .ant-col:nth-child(2) {
                font-weight: normal;
            }
            label {
                font-weight: bold;
            }
        }
        
    }
}

.audit-log {
    .header {
        background-color: #2a78e4 !important;
        border-bottom: 0 !important;
        color: #ffffff !important;
    }

    .header__inner {
        background-color: #2a78e4 !important;
        color: #ffffff !important;
    }

    .sub-header {
        background-color: #2a78e4;
        margin-bottom: 56px;
        padding-top: 16px;
        padding-bottom: 36px;
        .backlink a {
            color: #ffffff !important;
        }
    }

    .sub-header__inner {
        width: 80%;
        margin: auto;
        color: #ffffff;
    }

    .separator-vert {
        background-color: #ffffff !important;
    }

    .icon-hamburger {
        color: #ffffff !important;
    }

    .icon-arrow-left {
        height: 20px;
    }
    
    .ant-breadcrumb {
        color: #ffffff !important;
    }

    .ant-breadcrumb-separator {
        color: #ffffff !important;
    }

    .ant-breadcrumb-link {
        color: #ffffff !important;
    }
}

.audit-log-modal {
    .ant-modal-footer {
        text-align: center;
    }
}