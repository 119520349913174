.expandRow {
    .ant-table-wrapper {
        height: inherit;
        .ant-spin-nested-loading {
            height: inherit;
            .ant-spin-container {
                height: inherit;
                .ant-table {
                    height: inherit;
                    .ant-table-container {
                        height: inherit;
                        .ant-table-content {
                            height: inherit;
                            table {
                                height: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}